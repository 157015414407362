<!-- 消息条目 -->
<template>
  <div class="message">
    <!-- 7:编辑校区 9:禁用校区 11:取消禁用校区 -->
    <div v-if="msgItem.type == 7 || msgItem.type == 9 || msgItem.type == 11">
      您<span v-if="msgItem.type == 7">编辑</span><span v-if="msgItem.type == 9">禁用</span
      ><span v-if="msgItem.type == 11">取消禁用</span>了校区（学校名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）账户<span class="tel_color">{{ msgItem.NAME[1] }}</span>
    </div>

    <!-- 13:新增校区老师账号 15:编辑校区老师账号 17:删除校区老师账号 -->
    <div v-else-if="msgItem.type == 13 || msgItem.type == 15 || msgItem.type == 17">
      您成功<span v-if="msgItem.type == 13">新增</span><span v-if="msgItem.type == 15">编辑</span><span v-if="msgItem.type == 17">删除</span>了教师账户<span
        class="com_color"
        >{{ msgItem.NAME[0] }}</span
      >（教师手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >，关联校区：<span class="com_color">{{ msgItem.NAME[2] }}</span
      >学校）
    </div>

    <!-- 19:校长新增老师通知   21:校长编辑老师通知  23:校长删除老师   -->
    <div v-else-if="msgItem.type == 19 || msgItem.type == 21 || msgItem.type == 23">
      您成功<span v-if="msgItem.type == 19">新增</span><span v-if="msgItem.type == 21">编辑</span><span v-if="msgItem.type == 23">删除</span>了教师账户<span
        class="com_color"
        >{{ msgItem.NAME[0] }}</span
      >（教师手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）
    </div>

    <!-- 25 管理员为班级更换老师  26 校长为班级换老师  -->
    <div v-else-if="msgItem.type == 26 || msgItem.type == 25">
      您已将班级<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（ID：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）的教师替换为<span class="com_color">{{ msgItem.NAME[2] }}</span
      >（<span class="tel_color">{{ msgItem.NAME[3] }}</span
      >）
    </div>

    <!-- 28 管理员编辑无校区学生  -->
    <div v-else-if="msgItem.type == 28">
      您编辑了学生账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）
    </div>

    <!-- 29 校长新增本校区学生池学生 30 校长编辑本校区的学生  -->
    <div v-else-if="msgItem.type == 29 || msgItem.type == 30">
      您<span v-if="msgItem.type == 29">新增</span><span v-if="msgItem.type == 30">编辑</span>了学生账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）
    </div>

    <!-- 31 校长为本校区学生池的学生编辑课时  -->
    <div v-else-if="msgItem.type == 31">
      您编辑了学生账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）课时
    </div>

    <!-- 32 校长为本校区学生池的学生分班 33 校长为本校区学生池的学生转班 34 校长为本校区学生池的学生补课  -->
    <div v-else-if="msgItem.type == 32 || msgItem.type == 33 || msgItem.type == 34">
      您为学生账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）进行了<span v-if="msgItem.type == 32">分班</span><span v-if="msgItem.type == 33">转班</span><span v-if="msgItem.type == 34">补课操作</span>
    </div>

    <!-- 36 老师为本班级学生进行考勤操作 -->
    <div v-else-if="msgItem.type == 36">
      您为班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）进行了考勤
    </div>

    <!-- 37 老师为本班级学生进行补课操作 77 老师为本班级学生进行分班操作  78 老师为本班级学生进行转班操作-->
    <div v-else-if="msgItem.type == 37 || msgItem.type == 77 || msgItem.type == 78">
      您为学生账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）进行了<span v-if="msgItem.type == 77">分班</span><span v-if="msgItem.type == 78">转班</span><span v-if="msgItem.type == 37">补课</span>操作
    </div>

    <!-- 38 校长新增班级  40 校长编辑班级 -->
    <div v-else-if="msgItem.type == 38 || msgItem.type == 40">
      您<span v-if="msgItem.type == 38">新增</span><span v-if="msgItem.type == 40">编辑</span>了班级（班级名称：<span class="com_color">{{
        msgItem.NAME[0]
      }}</span
      >）
    </div>

    <!-- 44 删除班级  -->
    <div v-else-if="msgItem.type == 44">
      您的班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）已被删除
    </div>

    <!-- 45 校长为班级排课  -->
    <div v-else-if="msgItem.type == 45">
      您为班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）进行了排课
    </div>

    <!-- 47 校长为班级添加学员  -->
    <div v-else-if="msgItem.type == 47">
      您为班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）添加了学员<span class="com_color">{{ msgItem.NAME[1] }}</span
      >（学生手机号：<span class="tel_color" :title="msgItem.NAME[2]">{{ msgItem.NAME[2] }}</span
      >）
    </div>

    <!-- 49 校长为班级移除学员 -->
    <div v-else-if="msgItem.type == 49">
      <!-- {{msgItem.STATE==0?"临时":"正式"}} -->
      <!-- 您将学员<span class="com_color">{{ msgItem.NAME[0] }}</span>（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span>）、<span class="com_color">{{ msgItem.NAME[2] }}</span>（学生手机号：<span class="tel_color">{{ msgItem.NAME[3] }}</span>）从班级（班级名称：<span class="com_color">{{ msgItem.NAME[4] }}</span>）移除 -->
      您将学员<span class="com_color">{{ msgItem.NAME[0] }}</span
      >从班级（班级名称：<span class="com_color">{{ msgItem.NAME[1] }}</span
      >）移除
    </div>

    <!-- 51 校长调课 -->
    <div v-else-if="msgItem.type == 51">
      您为班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）进行了调课
    </div>

    <!-- 53 校长取消课程 -->
    <div v-else-if="msgItem.type == 53">
      您为班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）取消了<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >的课程
    </div>

    <!-- 55 老师为学生评价 -->
    <div v-else-if="msgItem.type == 55">
      您为学生<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）进行了课程评价
    </div>

    <!-- 56 校长导出排课记录 -->
    <div v-else-if="msgItem.type == 56">您成功导出了排课记录</div>

    <!-- 57 管理员为校区新增教师账号 59 管理员为校区新增学生账号 -->
    <div v-else-if="msgItem.type == 57 || msgItem.type == 59">
      您为校区（学校名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）新增了<span class="com_color">{{ msgItem.NAME[1] }}</span
      >个<span class="com_color">{{ msgItem.NAME[2] }}</span
      >{{ msgItem.type == 57 ? "老师" : "学生" }}账号（{{ msgItem.NAME[3] }}之前有效）
    </div>

    <!-- 61 管理员为校区恢复教师账号 63 管理员为校区恢复学生账号 -->
    <div v-else-if="msgItem.type == 61 || msgItem.type == 63">
      您为校区（学校名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）恢复了<span class="com_color">{{ msgItem.NAME[1] }}</span
      >个{{ msgItem.type == 61 ? "老师" : "学生" }}账号（{{ msgItem.NAME[2] }}之前有效）
    </div>

    <!-- 65 管理员为校区修改教师账号到期时间  67 管理员为校区修改学生账户到期时间 -->
    <div v-else-if="msgItem.type == 65 || msgItem.type == 67">
      您为校区（学校名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）修改了<span class="com_color">{{ msgItem.NAME[1] }}</span
      >个{{ msgItem.type == 65 ? "教师" : "学生" }}账号的使用期限（由{{ msgItem.NAME[2] }}到期修改为{{ msgItem.NAME[3] }}到期）
    </div>

    <!-- 69 管理员为校区编辑减少了教师账户到期时间 71 管理员为校区编辑减少学生账户到期时间 -->
    <div v-else-if="msgItem.type == 69 || msgItem.type == 71">
      您为校区（学校名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）减少了<span class="com_color">{{ msgItem.NAME[1] }}</span
      >个<span class="com_color">{{ msgItem.NAME[2] }}</span
      >{{ msgItem.type == 69 ? "老师" : "学生" }}账号（{{ msgItem.NAME[3] }}之前有效）
    </div>

    <!-- 73 超级管理员新建普通管理员 74 超级管理员禁用普通管理员 75 超级管理员启用普通管理员 76 超级管理员编辑普通管理员 -->
    <div v-else-if="msgItem.type == 73 || msgItem.type == 74 || msgItem.type == 75 || msgItem.type == 76">
      您<span v-if="msgItem.type == 73">新增</span><span v-if="msgItem.type == 76">编辑</span><span v-if="msgItem.type == 74">禁用</span
      ><span v-if="msgItem.type == 75">启用</span>了普通管理员<span class="com_color">{{ msgItem.NAME[0] }}</span
      >（管理员账号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）
    </div>

    <!-- 8 超级管理员/普通管理员发送给校长的编辑校区消息 10 发送给校长的禁用消息 12 发送给校长的取消禁用消息 -->
    <div v-else-if="msgItem.type == 8 || msgItem.type == 10 || msgItem.type == 12">
      <span v-if="msgItem.type == 8">您的校区资料已更新！</span>
      <span v-if="msgItem.type == 10">您的校区已被禁用，详情请咨询客服老师。</span>
      <span v-if="msgItem.type == 12">您的校区已被取消禁用</span>
    </div>

    <!-- 14 发送给校长的新增校区老师账号消息 16 发送给校长的编辑校区老师账号消息 18 发送给校长的删除老师账号消息 -->
    <div v-else-if="msgItem.type == 14 || msgItem.type == 16 || msgItem.type == 18">
      <span v-if="msgItem.type == 14"
        >您的校区新增一位教师{{ msgItem.NAME[0] }}（教师手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
        >）</span
      >
      <span v-if="msgItem.type == 16"
        >您的校区教师{{ msgItem.NAME[0] }}(手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
        >）的档案及课程权限已更新</span
      >
      <span v-if="msgItem.type == 18"
        >您校区的教师<span class="com_color">{{ msgItem.NAME[0] }}</span
        >(手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
        >）已被删除</span
      >
    </div>

    <!-- 20 发送给老师的新增通知  22 发送给老师的编辑通知  -->
    <div v-else-if="msgItem.type == 20 || msgItem.type == 22">
      <span v-if="msgItem.type == 20"
        >欢迎加入<span class="com_color">{{ msgItem.NAME[0] }}</span
        >学校</span
      >
      <span v-if="msgItem.type == 22">您的个人档案及课程权限已更新</span>
    </div>

    <!-- 24 管理员更换老师账号    -->
    <div v-else-if="msgItem.type == 24 || msgItem.type == 80">
      您已将教师账户<span class="com_color">{{ msgItem.NAME[0] }}</span
      >(教师手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >)更换为：<span class="com_color">{{ msgItem.NAME[2] }}</span
      >(教师手机号：<span class="tel_color">{{ msgItem.NAME[3] }}</span
      >)
    </div>

    <!-- 27 发送给老师的换老师消息  -->
    <div v-else-if="msgItem.type == 27">
      您的名下添加了班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）
    </div>

    <!-- 35 发送给老师的补课操作  -->
    <div v-else-if="msgItem.type == 35">
      您班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）的学生（学生手机号：<span class="tel_color">{{ msgItem.NAME[1] }}</span
      >）完成了补课，请及时完成学情评价！
    </div>

    <!-- 39 发送给老师的新增班级消息  -->
    <div v-else-if="msgItem.type == 39">
      您的名下添加了班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）
    </div>

    <!-- 42 发送给老师的编辑班级信息   -->
    <div v-else-if="msgItem.type == 42">
      您的班级（班级ID：<span class="tel_color">{{ msgItem.NAME[0] }}</span
      >）名称由<span class="com_color">{{ msgItem.NAME[1] }}</span
      >修改为：<span class="com_color">{{ msgItem.NAME[2] }}</span>
    </div>

    <!-- 41 发送给老师的名下班级修改名称消息  -->
    <div v-else-if="msgItem.type == 41">
      您的班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）基本信息有更新，请及时查看！
    </div>

    <!-- 43 发送给老师的名下班级修改名称消息  -->
    <div v-else-if="msgItem.type == 43">
      您删除了班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）
    </div>

    <!-- 46 发送给老师的班级排课信息 -->
    <div v-else-if="msgItem.type == 46">
      您的班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）有新的排课，请及时查看！
    </div>

    <!-- 48 发送给老师的班级添加学员信息  -->
    <div v-else-if="msgItem.type == 48">
      您的班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）有学员<span class="com_color">{{ msgItem.NAME[1] }}</span
      >（手机号：<span class="tel_color">{{ msgItem.NAME[2] }}</span
      >）加入，请及时查看！
    </div>

    <!-- 50 发送给老师的班级移除学员信息 -->
    <div v-else-if="msgItem.type == 50">
      您班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）学员{{ msgItem.NAME[1] }}（手机号：<span class="tel_color">{{ msgItem.NAME[2] }}</span
      >）已被移除，请及时查看！
    </div>

    <!-- 52 发送给老师的调课消息 -->
    <div v-else-if="msgItem.type == 52">
      您班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）的排课有变动，请及时查看！
    </div>

    <!-- 54 发送给老师的取消课程消息 -->
    <div v-else-if="msgItem.type == 54">
      您班级（班级名称：<span class="com_color">{{ msgItem.NAME[0] }}</span
      >）{{ msgItem.NAME[1] }}的课程已取消，请及时查看！
    </div>

    <!-- 58 发送给校长的新增教师账号消息  60 发送给校长的新增学生账号消息 -->
    <div v-else-if="msgItem.type == 58 || msgItem.type == 60">
      您的校区新增了<span class="com_color">{{ msgItem.NAME[0] }}</span
      >个<span class="com_color">{{ msgItem.NAME[1] }}</span
      >{{ msgItem.type == 58 ? "教师" : "学生" }}账号（{{ msgItem.NAME[2] }}之前有效）
    </div>

    <!-- 62 发送给校长的恢复教师账号消息 64 发送给校长的恢复学生账号消息 -->
    <div v-else-if="msgItem.type == 62 || msgItem.type == 64">
      您的校区恢复了<span class="com_color">{{ msgItem.NAME[0] }}</span
      >个{{ msgItem.type == 62 ? "教师" : "学生" }}账号（{{ msgItem.NAME[1] }}之前有效）
    </div>

    <!-- 66 发送给校长的修改教师账号到期时间消息 68 发送给校长的修改学生账户到期时间 -->
    <div v-else-if="msgItem.type == 66 || msgItem.type == 68">
      您的校区<span class="com_color">{{ msgItem.NAME[0] }}</span
      >个{{ msgItem.type == 66 ? "教师" : "学生" }}账号的使用期限由{{ msgItem.NAME[1] }}到期修改为{{ msgItem.NAME[2] }}到期。
    </div>

    <!-- 70 发送给校长的编辑减少了教师账户到期时间消息 72 发送给校长的编辑减少学生账户到期时间消息 -->
    <div v-else-if="msgItem.type == 70 || msgItem.type == 72">
      您的校区<span class="com_color">{{ msgItem.NAME[0] }}（{{ msgItem.NAME[1] }}之前有效）</span
      >{{ msgItem.type == 70 ? "教师" : "学生" }}账号数有变动，请核对！
    </div>

    <!-- 79 导出课消记录 -->
    <div v-else-if="msgItem.type == 79">您成功导出了课消记录</div>

    <!-- 81 超级管理员/普通管理员 续费消息 -->
    <div v-else-if="msgItem.type == 81">
      {{ msgItem.NAME[0] }}{{ msgItem.NAME[1] }}{{ msgItem.NAME[2]
      }}<span class="com_color">{{ msgItem.NAME[3] == 1 ? `老师` : "学生" }}账号({{ `${msgItem.NAME[4]}：${msgItem.NAME[5]}` }})</span>（{{
        msgItem.NAME[6]
      }}之前有效）即将到期，请尽快联系客户确认续期！
    </div>

    <!-- 82 校长 续费消息 -->
    <div v-else-if="msgItem.type == 82">
      您校区的{{ msgItem.NAME[0] }}{{ msgItem.NAME[1] }}
      <span class="com_color">{{ msgItem.NAME[2] == 1 ? `老师` : "学生" }}账号({{ `${msgItem.NAME[3]}：${msgItem.NAME[4]}` }})</span
      >即将到期，为了不影响您校区的正常运营，请尽快与客服老师联系！
    </div>

    <!-- 84 校长 校区账户余额不足 -->
    <div v-else-if="msgItem.type == 84">您校区的剩余可用账户不足，为了不影响您校区的正常运营，请尽快与客服老师联系！</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
  components: {},
  props: ["msgItem"]
};
</script>
<style lang="scss" scoped>
.message {
  font-size: 14px;
  .com_color {
    color: #0099ff;
  }
  .tel_color {
    color: #24a1d7;
  }
}
</style>
