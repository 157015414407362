//系统消息
<template>
  <div class="sys_message">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="sys_message_content">
          <!-- 顶部操作栏 -->
          <div class="top_action margin_left_14">
            <!-- 接收时间 -->
            <div class="class_time">
              <span class="margin_right_10">接收时间</span>

              <!-- 日期选择 :picker-options="pickerOptions"  -->
              <el-date-picker
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                v-model="chooseTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
              >
              </el-date-picker>
            </div>

            <div class="flex_1"></div>

            <!-- 全选,反选以及批量删除 -->
            <div class="top_r_info margin_right_14" v-if="tableData && tableData.length > 0">
              <span @click.stop="doSelectAll" class="choose_bt margin_right_20">{{ isAll ? "反选" : "全选" }}</span>
              <!-- 批量删除 -->
              <el-button :loading="showDelLoading" type="primary" size="medium" @click.stop="doDelAllAction"> 批量删除</el-button>
            </div>
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <!-- 表格部分  -->
            <el-table ref="table_sys_m" style="width: 98%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 选中操作 -->
              <el-table-column align="center" width="60">
                <template slot-scope="scope">
                  <span
                    @click.stop="doSelectAction(scope.row)"
                    :class="['common_box', 'iconfont', scope.row.selected ? 'icon-checkbox' : 'icon-CheckboxUnchecked']"
                  ></span>
                </template>
              </el-table-column>

              <!-- 序号  -->
              <el-table-column type="index" label="序号" align="center" width="80">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 标题  -->
              <el-table-column align="center" label="标题" min-width="20%">
                <template slot-scope="scope">
                  <!-- 消息条目 -->
                  <Message :msgItem="scope.row"></Message>
                </template>
              </el-table-column>

              <!-- 时间  -->
              <el-table-column prop="time" align="center" :show-overflow-tooltip="true" label="时间" width="220"></el-table-column>

              <!-- 操作  -->
              <el-table-column align="center" label="操作" width="230">
                <template slot-scope="scope">
                  <!-- 删除 -->
                  <el-button @click="doDelAction(scope.row)" type="text" size="small">删除</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- 顶部的分页器部分 bom_page -->
            <div class="margin_left_10 margin_top_14" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>

        <!-- 删除系统消息的弹框 -->
        <DelSysMessage
          @refreshPageData="refreshPageData"
          :showDialog="showDelDialog"
          @close="showDelDialog = false"
          v-if="showDelDialog"
          :actionItem="actionItem"
        ></DelSysMessage>

        <!-- 批量删除系统消息的弹框  -->
        <DelSysMessageAll
          @refreshPageData="refreshPageData"
          :showDialog="showDelAllDialog"
          @close="showDelAllDialog = false"
          v-if="showDelAllDialog"
          :delIds="delIds"
        ></DelSysMessageAll>
      </template>
    </Layout>
  </div>
</template>
<script>
import Message from "@/views/systemmanagement/Message";
import DelSysMessage from "@/components/dialog/DelSysMessage";
import DelSysMessageAll from "@/components/dialog/DelSysMessageAll";
export default {
  data() {
    return {
      //是否显示加载框
      showLoading: 1,

      //选择的日期
      chooseTime: "",

      //日期选择的配置项
      // pickerOptions: {
      //     //设置禁用日期,2022年1月1日之前的不允许选择,最多选择到当前日期
      //     disabledDate(time) {
      //         let dates = new Date("2021-12-31");
      //         return (
      //             time.getTime() <= dates.getTime() ||
      //             time.getTime() >= new Date().getTime()
      //         );
      //     },
      // },

      //配置时间
      selectDate: "",

      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime();
          if (maxDate) {
            this.selectDate = "";
          }
        },
        disabledDate: (time) => {
          // time.getTime()获取的是时间戳
          // 只能选择365天的范围且不能大于当前日期
          const choiceDateTime = new Date(this.selectDate).getTime();
          const minTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() - 12);
          const maxTime = new Date(choiceDateTime).setMonth(new Date(choiceDateTime).getMonth() + 12);
          const min = minTime;
          const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
          const max = newDate < maxTime ? newDate : maxTime;
          // 如果已经选中一个日期 则 返回 该日期前后12个月时间可选,后12个月最大为当前日期
          if (this.selectDate) {
            return time.getTime() < min || time.getTime() > max;
          }
          // 若一个日期也没选中 则 返回 当前日期以前日期可选
          return time.getTime() > newDate;
        },
      },

      //搜索学校名称关键字
      schoolName: "",

      //当前页面显示的列表数据
      tableData: [],

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [10, 14, 18],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格的宽度
      tableWidth: 0,

      //表格的最大高度
      maxHeight: 0,

      //搜索的其实日期
      start_date: "",
      end_date: "",

      //批量删除的加载框
      showDelLoading: false,

      //是否是全部选中
      isAll: false,

      //删除条目的弹框
      showDelDialog: false,

      //操作的条目对象
      actionItem: null,

      //批量删除
      delIds: "",

      //批量删除消息弹框控制值
      showDelAllDialog: false,
    };
  },
  created() {
    this.initData();

    //请求数据
    this.httpData();
  },

  mounted() {
    //发送一个清空头部消息的数据
    this.$bus.emit("updateUnreadMsg");
  },
  methods: {
    //初始化表格的最大高度
    initData() {
      //获取用户角色
      this.role = this.getUserRole();
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 200;
    },

    /**
     * 请求数据
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage;
      param.pagecount = this.pageSize;

      //startTime开始时间
      if (!this.isEmpty(this.start_date)) {
        param.startTime = this.start_date;
      }

      //endTime 结束时间
      if (!this.isEmpty(this.end_date)) {
        param.endTime = this.end_date;
      }

      this.$http
        .fetchPost(this.$api.MESSAGEREMINDER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;
          // console.log("请求到的数据是:", result);
          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_sys_m.bodyWrapper.scrollTop = 0;
          });
          if (result.state == 200) {
            //获取返回对象
            let detailList = result.data.messageReminderPage;
            if (!this.isEmpty(detailList)) {
              //获取总条数
              this.totalSize = Number.parseInt(detailList.totalCount);
              //先清空一下表格数据
              this.tableData.splice(0, this.tableData.length);

              if (this.totalSize != 0) {
                //获取列表数据
                this.tableData = detailList.list;

                //组装一下表格数据
                this.tableData.forEach((item, index) => {
                  //序号
                  this.$set(item, "index", index + 1);
                  //是否选中
                  this.$set(item, "selected", false);
                  //时间
                  this.$set(item, "time", this.formatDate(item.createTime, "yyyy-MM-dd HH:mm:ss"));
                });
              }
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      // console.log("当前页显示的条数是:", this.pageSize);
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },

    //选中或取消的操作
    doSelectAction(item) {
      item.selected = !item.selected;
      //同步一下全选反选的状态
      this.hasAllSelected() ? (this.isAll = true) : (this.isAll = false);
    },

    //判断当前页表格数据是否全部选中,使用ever函数用于判断数组中是否任意一个元素都符合判断条件，
    hasAllSelected() {
      let res = this.tableData.every((item) => {
        return item.selected == true;
      });
      return res;
    },

    //删除的操作
    doDelAction(item) {
      this.actionItem = item;
      this.showDelDialog = true;
    },

    //批量删除的操作
    doDelAllAction() {
      if (this.hasSelectedItem()) {
        //证明有选中的元素
        this.delIds = this.getIds();
        this.showDelAllDialog = true;
      } else {
        //没有选中的元素
        this.showWarnMsg("未选中当前页的任何条目");
      }
    },

    //全选反选的操作
    doSelectAll() {
      this.isAll = !this.isAll;
      this.sysTabDataState();
    },

    //同步当前表格数据状态的方法
    sysTabDataState() {
      this.tableData.forEach((item) => {
        if (this.isAll) {
          if (!item.selected) {
            item.selected = true;
          }
        } else {
          if (item.selected) {
            item.selected = false;
          }
        }
      });
    },

    //判断当前页表格数据是否有选中
    hasSelectedItem() {
      let res = false;
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].selected) {
          res = true;
          break;
        }
      }
      return res;
    },

    //获取所有选中的条目,使用-拼接id
    getIds() {
      let itemsArr = this.tableData.filter((item) => {
        return item.selected;
      });

      let idsArr = itemsArr.map((item) => {
        return item.id;
      });

      return idsArr.join("-");
    },

    /**
     * 刷新页面数据的方法
     */
    refreshPageData() {
      this.currPage = 1;
      this.httpData();
    },
  },
  computed: {},
  components: {
    Message,
    DelSysMessage,
    DelSysMessageAll,
  },
  watch: {
    //选择时间的监听
    chooseTime(newValue) {
      if (!this.isEmpty(newValue)) {
        this.start_date = this.chooseTime[0];
        this.end_date = this.chooseTime[1];
        // console.log("选择时间是:", this.start_date, this.end_date);
        //判断一下选择的时间间隔,最小1天,最大12个月
        if (!this.isRightTimeRange(this.start_date, this.end_date)) {
          this.showWarnMsg("选择的时间间隔必须大于1天");
          return;
        }
        //判断一下选择的时间间隔是否超过12个月
        if (this.isOverTimeRange(this.startTime, this.endTime)) {
          this.showWarnMsg("选择的时间间隔最大为12个月");
          return;
        }
      } else {
        this.start_date = "";
        this.end_date = "";
      }
      //还原页码重新请求数据
      this.currPage = 1;
      this.httpData();
    },
  },
};
</script>
<style lang="scss" scoped>
.sys_message {
  width: 100%;
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;

  //顶部操作栏
  .top_action {
    height: 60px;
    display: flex;
    align-items: center;

    //时间
    .class_time {
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
        width: 280px;
      }
      ::v-deep .el-range-separator {
        line-height: 30px;
        margin-right: 6px;
        margin-left: 2px;
      }

      ::v-deep .el-range__icon {
        line-height: 30px;
      }
    }

    .top_r_info {
      display: flex;
      align-items: center;
      justify-content: center;

      .choose_bt {
        cursor: pointer;
      }

      .search_bt_active {
        background: #409eff;
        color: white;
        cursor: pointer;
      }

      .commit_bt {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
      }
    }
  }
  .dv_table_view_content {
    height: 100%;
    margin-top: 0;
    .common_box {
      cursor: pointer;
      font-size: 18px;
      color: #409eff;
    }
  }
}
</style>
