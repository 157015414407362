//删除系统消息
<template>
    <div class="del_sys_message_dialog">
        <CommonDialog @close="close" :isCenter="isCenter" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="dialog_content">

                    <!-- 间隔线 -->
                    <el-divider></el-divider>

                    <!-- 提示信息部分 -->
                    <div class="msg_info">确认删除此条消息吗？</div>

                    <!-- 替换按钮  -->
                    <div class='com_dv'>
                        <el-button :loading="showLoading" :class="['com_bt_action','commit_bt','search_bt_active']" @click.stop="doSaveAction">确&nbsp;&nbsp;&nbsp;&nbsp;定</el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            title: "删除消息",

            isCenter: false,

            width: "360px",

            //提交状态值
            showLoading: false,
        };
    },
    created() {},
    methods: {
        //关闭的方法
        close() {
            this.$emit("close");
        },

        //确定
        doSaveAction() {
            this.showLoading = true;
            let param = this.getHttpParams();
            param.type = 2;
            param.id = this.actionItem.id;
            this.$http
                .fetchPost(this.$api.MESSAGEREMINDER, param)
                .then((res) => {
                    let result = res.data;
                    if (result.state == 200) {
                        setTimeout(() => {
                            //操作成功,关闭
                            this.close();

                            this.showLoading = false;
                            //刷新页面数据
                            this.$emit("refreshPageData");
                        }, 500);
                    } else {
                        this.showLoading = false;
                        this.showWarnMsg(`${res.data.msg},不能进行删除操作`);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.showErrorMsg("发生异常，操作失败");
                });
        },
    },
    computed: {},
    components: {},
    props: ["showDialog", "actionItem"],
};
</script>
<style lang="scss" scoped>
.del_sys_message_dialog {
    ::v-deep .el-dialog__body {
        padding: 0;
    }
    .dialog_content {
        padding-top: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .msg_info {
            min-height: 130px;
            display: flex;
            font-weight: bold;
            justify-content: center;
            align-items: center;
        }

        .com_dv {
            align-items: center;
            justify-content: center;
            display: flex;
            margin-bottom: 20px;

            .search_bt_active {
                background: #409eff;
                color: white;
                cursor: pointer;
            }

            .search_bt_normal {
                background: #cfd1d4;
                color: white;
                cursor: not-allowed;
            }

            .commit_bt {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 120px;
                border: none;
            }
        }
    }
}
</style>